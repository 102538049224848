html,
body {
    height: 100%;
}

body {
    display: flex;
    justify-content: center;
}

.App {
    min-width: 600px;
    padding-top: 35px;
}
