* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.align-items-center {
    align-items: center;
}

.col-full {
    flex-grow: 1;
    padding: 0 12px;
}

.flex {
    display: flex;
}

.nowrap {
    flex-wrap: nowrap;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.margin-bottom-base {
    margin-bottom: 10px;
}
.margin-bottom-base:last-child {
    margin-bottom: 0;
}
